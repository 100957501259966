import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="sharing-data" mdxType="Subpage">
      <h3>{`Sharing Data`}</h3>
      <p>{`So far we've learned a number of helpful things from Holtzblatt, et. al. regarding why we should, and how to go about conducting contextual research. Next we turn to how to pull this data into beneficial reports or visual models.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 5 (From Data to Insight: Contextual Design Models) from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/contextual-design-2nd/9780128011362/"
          }}>{`Holtzblatt`}</a>{`.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="data-modeling-methods" mdxType="Subpage">
      <h3>{`Data Modeling methods`}</h3>
      <p>{`To complete our initial study of contextual research let's survey the next three chapters in which Holtzblatt, et. al. describe three different types or categories of modeling data discovered during contextual research.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Skim Chapters 6—8 (The Affinity Diagram, Building Experience Models, Traditional Contextual Design Models) from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/contextual-design-2nd/9780128011362/"
          }}>{`Holtzblatt`}</a>{`.`}</p>
      </Callout>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      